import { gsap } from "gsap";
import { ScrollToPlugin, Power4 } from "gsap/all";
import Swal from 'sweetalert2';

gsap.registerPlugin(ScrollToPlugin);

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-calc')[0],
          offset = 10;

    const init = function() {
    
        const back = el.getElementsByClassName('js-back'),
              checkbox = el.getElementsByTagName('input'),
              results = el.getElementsByClassName('js-results'),
              submit = el.getElementsByClassName('js-submit')[0];

        const changeEventHandler = function(e) {            
            if (e.target.id === 'check-9') {
                for (let i = 0; i < checkbox.length - 1; i++) {
                    checkbox[i].checked = false;
                }
            } else {
                document.getElementById('check-9').checked = false;
            }
        }
        
        for (let i = 0; i < checkbox.length; i++) {
           checkbox[i].onchange = changeEventHandler;
        }


        const hide_results = function() {
            let tl = gsap.timeline()
                
            .to(window, { duration: .5, scrollTo: { y: el, offsetY: offset }, ease: Power4.easeOut })
        
            .to(el.querySelector('.active-result'), {
                autoAlpha: 0,
            }, '<')
            
            .to('.js-questions', {
                autoAlpha: 1,
            }, '<')
            
            el.querySelector('.active-result').classList.remove('active-result');
            document.body.classList.remove('results-visible');
            
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }            
        };


        const show_results = function(obj) {
            
            el.querySelector(obj).classList.add('active-result');
            document.body.classList.add('results-visible');

            let tl = gsap.timeline()
                
            .to(window, { duration: .5, scrollTo: { y: el, offsetY: offset }, ease: Power4.easeOut })
    
            .to('.js-questions', {
                autoAlpha: 0,
            }, '<')
            
            .to(obj, {
                autoAlpha: 1,
            }, '<')            
        };


        const verify = function() {
            
            let num = 0, element, norisk = false;
            
            for (let i = 0; i < checkbox.length; i++) {
                if (checkbox[i].checked) {
                    num ++;
                    if (checkbox[i].id === 'check-9') {
                        norisk = true;
                    }
                } 
            }
            
            if (num > 0) {
                
                if (norisk) {
                    show_results('.js-results--norisk');
                    
                } else {
                    if (num === 1) {
                        show_results('.js-results--medium');
                    }
                    
                    if (num >= 2) {
                        show_results('.js-results--high');
                    }
                }

            } else {

                Swal.fire({
                    title: "", 
                    text: el.dataset.nochoice, 
                    icon: "warning",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        gsap.to(window, { duration: 1, scrollTo: { y: el, offsetY: offset }, ease: Power4.easeOut })
                    }
                })
            }
        };
        
        for (let i = 0; i < back.length; i++) {
            back[i].addEventListener('click', hide_results);
        }

        submit.addEventListener('click', verify);    
    };

    el ? init() : false;    

}, false);
