import css from './sass/style.scss';

require('./js/calc.js');
require('./js/polyfills.js');
require('./js/init.js');
require('./js/ismobile.js');

require('./img/icons/high.png');
require('./img/icons/high@2x.png');

require('./img/icons/medium.png');
require('./img/icons/medium@2x.png');

require('./img/icons/norisk.png');
require('./img/icons/norisk@2x.png');
require('./img/assets/logo-krka.png');
require('./img/assets/logo-krka@2x.png');
require('./img/assets/logo-pt.png');
require('./img/assets/logo-pt@2x.png');